import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { Wrapper } from "./components/Utils";
import Footer from "./components/Footer";

const app = (
  <Wrapper className="container">
    <App />
    <Footer />
  </Wrapper>
);

ReactDOM.render(app, document.getElementById("root"));

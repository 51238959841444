import styled from "styled-components";
import { Colors, FontSize } from "../components/DesignSystem";

export const Wrapper = styled.div`
  font-family: "Rubik", sans-serif;
  color: #fff;
  padding-top: 0px;
  padding-bottom: 10px;
  background-color: ${Colors.background};
`;

export const Container = styled.div`
  display: flex;
  ${({ flex }) => flex && ` flex: ${flex}`};
  ${({ direction }) => direction && ` flex-direction: ${direction}`};
  ${({ width }) => width && ` width: ${width}`};
  ${({ height }) => height && ` height: ${height}`};
  ${({ justify }) => justify && ` justify-content: ${justify}`};
  ${({ align }) => align && ` align-items: ${align}`};
  ${({ top }) => top && ` margin-top: ${top}`};
  ${({ bottom }) => bottom && ` margin-bottom: ${bottom}`};
  ${({ right }) => right && ` margin-right: ${right}`};
  ${({ left }) => left && ` margin-left: ${left}`};
  ${({ minWidth }) => minWidth && ` min-width: ${minWidth}`};
  ${({ maxwidth }) => maxwidth && ` max-width: ${maxwidth}`};
  ${({ padding }) => padding && ` padding: ${padding}`};
  ${({ margin }) => margin && ` margin: ${margin}`};
`;

export const Button = styled.button`
  ${({ width }) => width && ` width: ${width}`};
  ${({ fontSize }) => fontSize && ` font-size: ${fontSize} !important`};
  ${({ padding }) => padding && ` padding: ${padding} !important`};
  ${({ margin }) => margin && ` margin: ${margin}`};
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  vertical-align: middle;
  cursor: pointer;
  color: ${Colors.primaryText};
  background-color: white;
  background-image: ${Colors.greenButtonGradient};
  box-shadow: ${Colors.buttonShadow};
  text-decoration: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: none;
  border-radius: 5px;
  font-weight: 100;
  font-size: ${FontSize.medium};
  text-transform: uppercase;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const ButtonLink = styled.a`
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  vertical-align: middle;
  cursor: pointer;
  color: ${Colors.primaryText};
  background-color: white;
  background-image: ${Colors.greenButtonGradient};
  box-shadow: ${Colors.buttonShadow};
  text-decoration: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: none;
  padding: 20px;
  margin: 6px 0;
  display: inline-block;
  border-radius: 5px;
  font-weight: 100;
  font-size: ${FontSize.medium};
`;

export const Link = styled.a`
  font-family: Rubik, sans-serif;
  cursor: pointer;
  color: ${Colors.primaryText};
  ${({ margin }) => margin && ` margin: ${margin}`};
  ${({ color }) => color && ` color: ${color} !important`};
  font-weight: 100;
  font-size: ${FontSize.xsmall};
  text-decoration: none;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
  i {
    font-size: 8px;
    margin-right: 10px;
    ${({ color }) => color && ` color: ${color} !important`};
  }
`;

export const IcoNetwork = styled.img`
  ${({ width }) => width && ` width: ${width}`};
  ${({ width }) => width && ` height: ${width}`};
  margin-right: 10px;
  border-radius: 5px;
`;

export const Title = styled.h1`
  font-weight: 300;
  font-size: 24px;
`;

export const Paragraph = styled.p`
  font-size: ${FontSize.small};
`;

export const Line = styled.hr`
  width: 100%;
  margin-top: 0;
  border: 0;
  border-top: 1px solid ${Colors.strokeGrey};
  margin-bottom: 10px;
`;
export const Row = styled.div`
  display: flex;
  font-size: 13px;
  padding: 2px 0 2px 0;
  justify-content: space-evenly;
  width: 100%;
  ${({ margin }) => margin && ` margin: ${margin}`};
  ${({ align }) => align && ` align-items: ${align}`};
  ${({ border }) => border && `border-bottom: 1px solid ${Colors.strokeGrey}`};
  ${({ flex }) => flex && ` flex: ${flex}`};
  ${({ width }) => width && ` width: ${width}`};
  ${({ height }) => height && ` height: ${height}`};
  ${({ justify }) => justify && ` justify-content: ${justify}`};
  ${({ align }) => align && ` align-items: ${align}`};
  ${({ top }) => top && ` margin-top: ${top}`};
  ${({ bottom }) => bottom && ` margin-bottom: ${bottom}`};
  ${({ right }) => right && ` margin-right: ${right}`};
  ${({ left }) => left && ` margin-left: ${left}`};
  ${({ minWidth }) => minWidth && ` min-width: ${minWidth}`};
  ${({ padding }) => padding && ` padding: ${padding}`};
  &:last-of-type {
    border: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ width }) => width && ` width: ${width}`};
  ${({ justify }) => justify && ` justify-content: ${justify}`};
  ${({ align }) => align && ` align-items: ${align}`};
`;

export const EllipsisWrapper = styled.div`
  max-width: 90%;
  display: inline-flex;
  flex-wrap: nowrap;
`;

export const Ellipsis = styled.div`
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const EllipsisEnd = styled.div`
  flex: 1 0 auto;
  white-space: nowrap;
  margin-left: -1px;
`;

import React from "react";

import KleverLogo from "../../assets/img/klever.svg";
import "./TronWalletGuide.scss";

const WEBSTORE_URL = "https://www.klever.finance/";

const logo = (
  <div className="logo">
    <img src={KleverLogo} alt="Klever logo" />
  </div>
);

const openTronWallet = () => {
  window.open(WEBSTORE_URL, "_blank");
};

const TronWalletGuide = (props) => {
  const { installed = false, loggedIn = false } = props;

  if (!loggedIn || !installed) {
    return (
      <div className="tronWalletGuide" onClick={openTronWallet}>
        <div className="info">
          <h1>Log in Required</h1>
          <p>Open this link from Klever Wallet 4 app.</p>
          <div className="download-call">
            <a href={WEBSTORE_URL} className="btn-download">
              Download
            </a>
            <br />
            <br />
            <p>Download Klever app to Use.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="tronWalletGuide" onClick={openTronWallet}>
      <div className="info">
        <h1>Wrong Network</h1>
        <h5>
          This is a BETA Test version. To ensure your funds are safe, please use
          Klever with Shata Network.
        </h5>
        Once installed, return back and refresh the page.
      </div>
      {logo}
    </div>
  );
};

export default TronWalletGuide;

import React from "react";
import packageJson from "../../../package.json";

import { FooterContainer } from "./elements";

export default class Footer extends React.Component {
  render() {
    return (
      <FooterContainer>
        Migration Staking Interface
        <br />
        Powered by Klever
        <br />v {packageJson.version}
      </FooterContainer>
    );
  }
}

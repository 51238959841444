import React from "react";
import { Row } from "../Utils";
import ConfirmDialog from "./confirmDialog";
import { bech32 } from "bech32";
import "./Banking.scss";

class BankingActions extends React.Component {
  state = {
    account: "",
    currentAPR: 100,
    amountToFreeze: "",
    accountToWithdraw: "",
    showConfirmFreeze: false,
    totalDeposited: 0,
    totalClaimed: 0,
    totalGains: 0,
    startDate: 0,
    endDate: 0,
    isWithdrawEnabled: false,
    maxTimeToRewards: 91,
    unfreezeDelay: 7,
    countdown: "0d 0h 0m 0s",
    countdownLabel: "Starts in: ",
    frozenAccount: {
      amount: 0,
      gains: 0,
      buckets: 0,
      withdrawTime: 0,
      canWithdraw: false,
      canUnfreeze: false,
      canDeposit: false,
      balance: 0,
    },
    showWithdrawAlert: false,
    results: {},
  };

  componentDidMount() {
    const { account } = this.props;

    this.setState(
      {
        account,
      },
      () =>
        this.tokenSync().then(
          () => (this.sync = setInterval(this.tokenSync, 10000))
        )
    );

    const countdownWatcher = setInterval(() => {
      let { startDate, endDate, countdownLabel } = this.state;

      if (startDate === 0 || endDate === 0) {
        return;
      }

      let now = new Date().getTime();
      let countdownDate = startDate?.getTime();
      if (countdownDate - now <= 0) {
        countdownDate = endDate.getTime();
        countdownLabel = "Ends in: ";
      }

      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.setState({
        countdown: days + "d " + hours + "h " + minutes + "m " + seconds + "s ",
        countdownLabel,
        maxTimeToRewards: days,
      });

      if (distance < 0) {
        clearInterval(countdownWatcher);
        this.setState({
          countdown: "",
          countdownLabel: "Event Finished!",
        });
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.sync) {
      clearInterval(this.sync);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { account } = this.props;
    const { account: accountNext } = nextProps;
    if (accountNext && accountNext !== account) {
      this.setState({ account: accountNext }, this.tokenSync);
    }
  }

  tokenSync = async () => {
    const { token, banking, decimals } = this.props;
    if (!token || !banking || !decimals) return;
    const { account, frozenAccount } = this.state;

    const r = await Promise.all([banking.contractStatus().call()])
      .then((r) => r)
      .catch((e) => {
        console.log("Retrying: ", e);
        return null;
      });
    if (!r) {
      // force token resync on error
      this.tokenSync();
      return;
    }
    const [stats] = r;

    let balance = 0;

    if (window.tronWeb.isAddress(account)) {
      const frozen = await banking.frozenAmount(account).call();
      if (!frozen) {
        // force token resync on error
        this.tokenSync();
        return;
      }
      frozenAccount.amount = frozen?.amount.toNumber() / 10 ** decimals;
      frozenAccount.gains = frozen?.gains.toNumber() / 10 ** decimals;
      frozenAccount.buckets = frozen?.buckets.toNumber();
      frozenAccount.withdrawTime = frozen?.withdrawTime.toNumber();

      frozenAccount.canWithdraw =
        stats?.isWthdrawEnabled &&
        frozen?.withdrawTime.toNumber() == 0 &&
        frozen?.amount > 0;

      frozenAccount.canDeposit =
        new Date(stats?.startDate.toNumber() * 1000).getTime() < Date.now() &&
        frozen?.withdrawTime.toNumber() === 0 &&
        frozen?.buckets < 10;
    }

    balance = await token.balanceOf(account).call();
    balance = balance.toNumber() / 10 ** decimals;

    this.setState({
      balance,
      totalDeposited: stats?.totalDeposited.toNumber() / 10 ** decimals,
      totalClaimed: stats?.totalClaimed.toNumber() / 10 ** decimals,
      totalGains: stats?.totalGains.toNumber() / 10 ** decimals,
      startDate: new Date(stats?.startDate.toNumber() * 1000),
      endDate: new Date(stats?.endDate.toNumber() * 1000),
      isWithdrawEnabled: stats?.isWthdrawEnabled,
      frozenAccount,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeOnlyNumbers = (e) => {
    if (/^[0-9]*$/i.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onFreeze = async () => {
    const { token, banking, decimals } = this.props;
    const { amountToFreeze, results } = this.state;
    this.setState({
      showConfirmFreeze: false,
      amountToFreeze: "",
    });

    const totalToFreeze = amountToFreeze * 10 ** decimals;
    const approval = await token
      .approve(process.env.REACT_APP_CONTRACT_BANK, totalToFreeze)
      .send();

    const result = await banking.deposit(totalToFreeze).send();
    this.setState(
      {
        results: {
          ...results,
          hash: "Hash: " + result,
        },
      },
      this.tokenSync
    );
  };

  onWithdraw = async () => {
    const { banking } = this.props;
    const { results, accountToWithdraw } = this.state;

    if (accountToWithdraw.length === 62) {
      const decodedAddress = bech32.decode(accountToWithdraw);

      const result = await banking
        .claim(bech32.fromWords(decodedAddress?.words))
        .send();

      this.setState(
        {
          results: {
            ...results,
            hash: "Hash: " + result,
          },
        },
        this.tokenSync
      );
    } else {
      this.setState(
        {
          results: {
            ...results,
            hash: "Invalid Account Address.",
          },
        },
        this.tokenSync
      );
    }
  };

  render() {
    const {
      account,
      balance,
      frozenAccount,
      currentAPR,
      amountToFreeze,
      accountToWithdraw,
      results,
      showConfirmFreeze,
      maxTimeToRewards,
      unfreezeDelay,
      countdown,
      countdownLabel,
      showWithdrawAlert,
    } = this.state;

    const { canDeposit, canWithdraw } = frozenAccount;

    var validDeposit = true;
    // validate amount
    if (amountToFreeze < 1000 || amountToFreeze > balance) {
      validDeposit = false;
    }

    return (
      <div>
        <ConfirmDialog
          open={showConfirmFreeze}
          onConfirm={this.onFreeze}
          onDismiss={() =>
            this.setState({
              showConfirmFreeze: false,
            })
          }
          currentAPR={currentAPR}
          amountToFreeze={amountToFreeze}
          maxTimeToRewards={maxTimeToRewards}
          unfreezeDelay={unfreezeDelay}
        />
        <br />
        <div className="apr">
          <h5>Current APR</h5>
          <p>{currentAPR}%</p>
        </div>
        <div className="form-group">
          <span className="input-title">Account / Address</span>
          <div className="input-container">
            <input
              placeholder="Wallet address"
              name="account"
              value={account}
              onChange={() => {}}
            />
          </div>
        </div>
        <div>
          {/* <h5 className="content-title">Deposit</h5> */}
          <div className="balance">
            <span className="input-title">Balance</span>
            <p>{balance} KLV</p>
          </div>
          {/* <span className="input-title">Deposit KLV (Minimum 1,000 KLV)</span> */}
          {/* <div className="input-container">
            <input
              placeholder="Amount to deposit"
              name="amountToFreeze"
              value={amountToFreeze}
              onChange={this.onChangeOnlyNumbers}
            />
            <div
              onClick={() => {
                this.setState({
                  amountToFreeze: Math.trunc(balance),
                });
              }}
            >
              MAX
            </div>
          </div> */}
          {/* <span className="alertDeposit">
            You need at least 50 TRX or 150K Energy to stake KLV{" "}
          </span> */}
          {/* <button
            className={
              canDeposit && validDeposit ? "btn-tw" : "btn-tw disabled"
            }
            disabled={!canDeposit || !validDeposit}
            onClick={() => {
              this.setState({
                showConfirmFreeze: true,
              });
            }}
          >
            Deposit
          </button> */}
        </div>
        <div className="countdown">
          <p>
            {" "}
            {countdownLabel} {countdown}
          </p>
        </div>
        <div className="info-container">
          <div>
            <span>Deposited Amount</span>
            <p>
              {frozenAccount.withdrawTime === 0 ? frozenAccount?.amount : 0}
            </p>
          </div>
          <div>
            <span>Gains</span>
            <p>{frozenAccount.withdrawTime === 0 ? frozenAccount?.gains : 0}</p>
          </div>
          <div>
            <span>Buckets</span>
            <p>
              {frozenAccount.withdrawTime === 0 ? frozenAccount?.buckets : 0}
            </p>
          </div>
        </div>
        <div className="separator" />

        <div>
          <h5 className="content-title">Withdraw</h5>
          <span className="input-title">
            KLV Account to withdraw (KleverChain Address)
          </span>
          <div className="input-container">
            <input
              placeholder="Kleverchain Account to Withdraw"
              name="accountToWithdraw"
              value={accountToWithdraw}
              onChange={this.onChange}
              onClick={() => {
                this.setState({
                  showWithdrawAlert: true,
                });
              }}
            />
          </div>
          {showWithdrawAlert && (
            <div className="withdraw-alert">
              <div>i</div>
              Please check the address carefully so as not to lose your funds.
              It must be a Klever Blockchain address. KleverChain Addresses
              always starts with klv1.
            </div>
          )}

          <button
            className={!canWithdraw ? "btn-tw disabled" : "btn-tw"}
            onClick={this.onWithdraw}
            disabled={!canWithdraw}
          >
            Withdraw
          </button>
          <Row justify="left">{results?.hash}</Row>
        </div>
      </div>
    );
  }
}

export default BankingActions;

import React  from 'react';
import { Row } from '../Utils'

class SwapActions extends React.Component {
  state = {
    swapRatio: 1,
      results: {
        swap: "",
      },
      amount: 0,
      balance: 0
  }

  componentDidMount(){
    const { account } = this.props;
    this.getData();
    this.setState({
      account
    }, ()=>this.balanceSync().then(()=>
      this.sync = setInterval(this.balanceSync, 10000)
    ));
  }
  componentWillUnmount(){
    if (this.sync) {
        clearInterval(this.sync);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { account } = this.props;
    const { account: accountNext } = nextProps;
    if (accountNext && accountNext !== account) {
      this.setState({ account: accountNext }, this.balanceSync);
    }
  }

  onChange = e =>{
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  getData = async () => {
    const { swap } = this.props;
    const ratio = await swap.swapRatio().call();
    this.setState({
      swapRatio: ratio.tokenSwapNumerator.toNumber()/ratio.tokenSwapDenominator.toNumber(),
    })
  }

  balanceSync = async () => {
    const { oldToken, decimals } = this.props;
    if(!oldToken || !decimals) return;
    const { account } = this.state;

    if (window.tronWeb.isAddress(account)){
      const balance = await oldToken.balanceOf(account).call().then( b => {
        return b.toNumber() / (10 ** decimals);
      });
      this.setState({
        balance
      })
      
    }
  }

  onSwap = async () => {
    const { oldToken, swap, decimals } = this.props;
    const { amount, results } = this.state;

    const toSwap = amount * (10 ** decimals);
    const approval = await oldToken.approve(process.env.REACT_APP_CONTRACT_SWAP, toSwap).send();
    console.log("Approval", approval);
    const result = await swap.swap(toSwap).send();
    this.setState({
      results: {
        ...results,
        swap: result
      }
    })
  }

  render() {
    const { account } = this.props;
    const {
      swapRatio,
      amount,
      results,
      balance
    } = this.state;

    return (
      <div>
        <br/>
        <div className="form-group" >
          <h5>Swap Ratio: 1.00-{swapRatio.toFixed(2)}</h5>
        </div>

        <div className="form-group" >
          <Row>
              <fieldset>
                  <legend>Account</legend>
                  <div className="input-wrapper">
                      <input placeholder='Wallet address'
                      name="account"
                      value={account}
                      onChange={ ()=>{} }/>
                  </div>
              </fieldset>
          </Row>
          <Row>
              <fieldset>
                  <legend>Available</legend>
                  <div className="input-wrapper">
                      <input placeholder='Available amount'
                      name="balance"
                      value={balance}
                      onChange={ ()=>{} }/>
                  </div>
              </fieldset>
          </Row>
          <Row>
            <fieldset>
              <legend>Swap</legend>
              <div className="input-wrapper">
                <input placeholder='Amount to swap'
                    name="amount"
                    value={amount}
                    onChange={ this.onChange }/>
              </div>
            </fieldset>
            <button
                className={ 'btn-tw' }
                onClick={ this.onSwap }
            >
                Confirm
            </button>
          </Row>
          <Row justify="left" top="10px">
            {results.swap}
          </Row>
        </div>
      </div>
    )
  }
}

export default SwapActions;

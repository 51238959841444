import React from "react";

import TronWalletGuide from "../TronWalletGuide";
import "./App.scss";
import "../../assets/fonts/tronwallet-fonts.css";
import "../../assets/fonts/tronwallet-icons.css";
import "../../assets/styles/tronwallet.scss";
//import { Colors, FontSize } from 'components/DesignSystem'
import KleverLogo from "../../assets/img/klever.svg";

import SwipeableViews from "react-swipeable-views";

import { StyledTabs, StyledTab, TabPanel } from "./elements";

import BankingActions from "../BankingActions";
import SwapActions from "../SwapActions";

class App extends React.Component {
  state = {
    tronWeb: {
      installed: false,
      loggedIn: false,
      testNet: false,
    },
    account: "",
    tabValue: 0,
  };

  handleChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  componentDidMount() {
    const loadWatcher = setInterval(async () => {
      if (window.tronWeb && window.tronWeb.ready) {
        clearInterval(loadWatcher);
        const installed = !!window.tronWeb;
        const tronWebState = {
          installed: installed,
          loggedIn: true,
        };

        const token = await window.tronWeb
          .contract()
          .at(process.env.REACT_APP_CONTRACT_DAPP);
        const banking = await window.tronWeb
          .contract()
          .at(process.env.REACT_APP_CONTRACT_BANK);

        const decimals = await token.decimals().call();

        this.setState({
          tronWeb: tronWebState,
          account: window.tronWeb.defaultAddress.base58,
          token,
          banking,
          decimals,
        });

        window.tronWeb.on("addressChanged", (addr) => {
          const { account } = this.state;
          // reload address
          if (account !== addr.base58) {
            this.setState({
              account: addr.base58,
            });
          }
        });
      }
    }, 200);
  }

  render() {
    const {
      tronWeb: {
        installed: tronWebInstalled,
        loggedIn: tronWebLoggedIn,
        testNet: tronWebTestNet,
      },
      account,
      tabValue,
      token,
      banking,
      decimals,
    } = this.state;

    const ready = tronWebInstalled && tronWebLoggedIn && !tronWebTestNet;

    return (
      <div className="flex-shrink-0">
        <br />

        <div className="container">
          <div className="header">
            <img src={KleverLogo} alt="Klever Logo" />
            <br />
            <br />
            <h4>Staking Migration</h4>
          </div>
          {ready && (
            <div>
              <StyledTabs value={tabValue} onChange={this.handleChange}>
                <StyledTab label="Staking KLV" />
                <StyledTab label="About" />
              </StyledTabs>
              <SwipeableViews axis={"x"} index={tabValue}>
                <TabPanel value={tabValue} index={0}>
                  <BankingActions
                    token={token}
                    banking={banking}
                    decimals={decimals}
                    account={account}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <p>
                    This is the Klever Mainnet Migration Contract. KLV holders
                    on Tron Network can stake their KLV starting on April 25th
                    until July 26th 2022.
                  </p>
                  <p>
                    Staked KLV will be available for withdraw to users' Mainnet
                    address on Klever Blockchain when the network goes live. An
                    APR of 100% will be applied to the staked KLV for the period
                    it was locked in the contract or until the end of the event
                    on July 26th 2022, should the user not withdraw before that
                    time after Mainnet is live.
                  </p>
                  <p>
                    An address can stake up to 10 buckets with a minimum of
                    1,000 KLV per bucket. For every stake in the Klever
                    Migration contract the holder needs to have a minimum of 50
                    TRX for the network fees of the TRON network. This is to
                    cover the energy cost for the staking transaction.
                  </p>
                  <p>
                    The withdraw process will only accept withdraw of the whole
                    amount locked from that account.
                  </p>
                </TabPanel>
              </SwipeableViews>
            </div>
          )}

          {!tronWebInstalled && <TronWalletGuide />}
          {tronWebInstalled && !tronWebLoggedIn && (
            <TronWalletGuide installed />
          )}
          {tronWebInstalled && tronWebLoggedIn && tronWebTestNet && (
            <TronWalletGuide installed loggedIn />
          )}
        </div>
      </div>
    );
  }
}

export default App;

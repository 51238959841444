import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class ConfirmDialogSlide extends Component {
  render() {
    const {
      open,
      onConfirm,
      onDismiss,
      currentAPR,
      amountToFreeze,
      maxTimeToRewards,
      unfreezeDelay,
    } = this.props;
    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onDismiss}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Confirm Deposit?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You have requested to deposit {amountToFreeze.toLocaleString()} KLV
            at an interest rate of {currentAPR}% APR for a max period of{" "}
            {maxTimeToRewards} days. The new migration contract ends on July
            26th 2022. This will freeze and lock your KLV in Klever Migration
            contract until withdraw to Klever Blockchain Mainnet is enabled.
            <br />
            Once Klever Blockchain Mainnet is live and KLV migration contract is
            enabled for withdrawal of funds, you will be able to request
            withdraw for the amount you froze and your rewards for the time you
            have staked KLV. Withdrawal may take up to {unfreezeDelay} days to
            receive in your Klever Mainnet address.
            <br />
            Do you agree with the term and conditions?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDismiss} color="primary">
            Disagree
          </Button>
          <Button onClick={onConfirm} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

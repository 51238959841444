export const Spacing = {
  none: 0,
  tiny: 2,
  xsmall: 4,
  small: 8,
  average: 14,
  medium: 16,
  big: 20,
  large: 32,
};

export const ScreenSizes = {
  xs: "400px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
};

export const ButtonSize = {
  xsmall: 20,
  small: 28,
  average: 39,
  medium: 42,
  big: 45,
  large: 50,
};

export const Colors = {
  background: "#191A2A",
  darkerBackground: "#10101A",
  greyBlue: "#9c9eb9",
  greySoft: "#eaeaea",
  ufoGreen: "#33d46c",
  dusk: "#3f415d",
  lemonYellow: "#f5ff30",
  darkThree: "#2e2f47",
  lightBackground: "#212132",
  lighterBackground: "#2d2e46",
  lightestBackground: "#3f415d",
  weirdGreen: "#3fe77b",
  purple: "#8520D0",
  lightPurple: "#696A9C",
  slateGrey: "#51526b",
  primaryText: "#FFFFFF",
  secondaryText: "#66688F",
  blackBackground: "#191a29",
  summaryText: "#999bb5",
  titleLabel: "#7476A2",
  strongBlue: "#501eec",
  strokeGrey: "#41435f",
  disabledText: "#453C5E",
  primaryGradient: ["#FF4465", "#F6CA1D"],
  buttonGradient: ["#FF4465", "#f8a92f", "#fc734b", "#f7b329", "#F4BC3A"],
  transactionCardGradient: ["#444663", "#2d2e46"],
  transactionsRewardsGradient: ["#FF4465", "#f7b229"],
  transactionSuccessGradient: ["#3de278", "#1c5631"],
  greenButtonGradient: "linear-gradient(rgb(35, 194, 119), rgb(57, 157, 108))",
  pinkBarGradient:
    "linear-gradient(132deg, rgb(238, 63, 113), rgb(125, 63, 241))",
  buttonShadow:
    "rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset, rgba(67, 90, 111, 0.06) 0px -1px 1px 0px inset",
  confirmed: "rgb(57,157,108)",
  unconfirmed: "#FF4465",
  yellow: "#F5FF30",
  green: "#a9ff68",
  red: "#9A2520",
  orange: "#ff7f28",
  redError: "#ff5454",
  shadow: "#000000",
  watermelon: "#ff4465",
  unselectedArrow: "#554D6C",
  selectedArrow: "#CF00A6",
  lightGray: "#66698c",
  darkGray: "#3f415b",
  RGB: {
    background: "25,26,42",
    darkerBackground: "16,16,26",
    lighterBackground: "45,46,70",
    lightestBackground: "63,65,93",
    primaryText: "255,255,255",
    secondaryText: "102,104,143",
    summaryText: "155,155,180",
  },
};

export const FontSize = {
  micro: "8px",
  xtiny: "9px",
  tiny: "11px",
  button: "12px",
  xsmall: "12px",
  smaller: "14px",
  small: "16px",
  average: "18px",
  medium: "24px",
  xlarge: "29px",
  large: "36px",
  xhuge: "44px",
  huge: "54px",
};
